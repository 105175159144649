import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/home/Intro/intro";
import Features from "../components/home/Features/features";
import LearnMore from "../components/home/LearnMore/learnMore";
import PatientJourney from "../components/home/PatientJourney/patientJourney";
import TimeLine from "../components/home/TimeLine/timeLine";
import Opinions from "../components/home/Opinions/opinions";
import TrustedBy from "../components/TrustedBy/trustedBy";
import { useTranslation } from "react-i18next";

const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title={t("menu.home")} />
      <Intro />
      <Features />
      <LearnMore />
      <PatientJourney />
      <TimeLine />
      <Opinions />
      <TrustedBy />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
