import * as styles from "./timeLineItem.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";

const TimeLineItem = ({
  timePeriod,
  Icon,
  titleId,
  descriptionId,
  hideArrow,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {timePeriod && <h4 className={styles.timePeriod}>{t(timePeriod)}</h4>}
      <div className={styles.iconWrapper}>
        {!hideArrow && <div className={styles.arrow} />}
        <div className={styles.icon}>
          <Icon />
        </div>
      </div>
      <div className={styles.title}>{t(titleId)}</div>
      <div className={styles.description}>{t(descriptionId)}</div>
    </div>
  );
};

export default TimeLineItem;
