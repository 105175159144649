import * as styles from "./opinions.module.scss";
import React from "react";
import face1 from "./face1.jpg";
import faceVip from "./faceVip.png";
import OpinionBox from "./OpinionBox/OpinionBox";
import VipBox from "./VipBox/vipBox";
import Review from "./Review/Review";
import Opinion from "./Opinion/Opinion";
import SliderArrows from "../../SliderArrows/SliderArrows";

const opinionsList = [
  {
    id: 1,
    photo: face1,
    type: "OPINION",
    name: "Debbie Evans",
  },
  {
    id: 2,
    type: "REVIEW",
    store: "Play Store",
    rate: 5,
  },
  {
    id: 3,
    type: "REVIEW",
    store: "App Store",
    rate: 4,
  },
  {
    id: 4,
    type: "REVIEW",
    store: "App Store",
    rate: 5,
  },
  {
    id: 5,
    type: "REVIEW",
    store: "App Store",
    rate: 5,
  },
  {
    id: 6,
    type: "REVIEW",
    store: "App Store",
    rate: 5,
  },
  {
    id: 7,
    type: "REVIEW",
    store: "App Store",
    rate: 5,
  },
];

const vip = {
  id: 0,
  photo: faceVip,
  type: "OPINION",
  name: "Jonathan Koslover",
};

class Opinions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItemIndex: 0,
      itemWidth: 0,
      isMobile: false,
    };
    this.sliderItem = React.createRef();
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      itemWidth: this.sliderItem.current.offsetWidth,
      isMobile: window.innerWidth < 768,
    });
  };
  nextHandler = () => {
    const { activeItemIndex, isMobile } = this.state;
    const itemsLength = opinionsList.length + (isMobile ? 1 : 0);
    if (activeItemIndex < itemsLength - 1) {
      this.setState({ activeItemIndex: activeItemIndex + 1 });
    } else {
      this.setState({ activeItemIndex: 0 });
    }
  };

  prevHandler = () => {
    const { activeItemIndex, isMobile } = this.state;
    const itemsLength = opinionsList.length + (isMobile ? 1 : 0);
    if (activeItemIndex > 0) {
      this.setState({ activeItemIndex: activeItemIndex - 1 });
    } else {
      this.setState({ activeItemIndex: itemsLength - 1 });
    }
  };

  render() {
    const { activeItemIndex, itemWidth, isMobile } = this.state;
    const sectionPadding = isMobile ? 0 : 90;
    const opinions = [...(isMobile ? [vip] : []), ...opinionsList];

    return (
      <div className={styles.opinionsWrapper}>
        {!isMobile && <VipBox {...vip} />}
        <div className={styles.opinionsSlider}>
          <div
            className={styles.viewPort}
            style={{
              transform: `translateX(${
                activeItemIndex * itemWidth * -1 - sectionPadding
              }px)`,
            }}
          >
            {opinions.map((option, index) => (
              <div ref={index === 0 && this.sliderItem} key={option.id}>
                <OpinionBox>
                  {option.type === "OPINION" && <Opinion {...option} />}
                  {option.type === "REVIEW" && <Review {...option} />}
                </OpinionBox>
              </div>
            ))}
          </div>
          <div className={styles.sliderControls}>
            <SliderArrows goNext={this.nextHandler} goPrev={this.prevHandler} />
          </div>
        </div>
      </div>
    );
  }
}

export default Opinions;
