import * as styles from "./timeLine.module.scss";
import React from "react";
import phoneIcon from "../../../assets/svg/timelinePhone.svg";
import historyIcon from "../../../assets/svg/timelineHistory.svg";
import injectionIcon from "../../../assets/svg/timelineInjection.svg";
import temperatureIcon from "../../../assets/svg/timelineTemperature.svg";
import communicationIcon from "../../../assets/svg/timelineCommunication.svg";
import paymentIcon from "../../../assets/svg/timelinePayment.svg";
import peopleIcon from "../../../assets/svg/timelinePeople.svg";
import engageIcon from "../../../assets/svg/timelineEngage.svg";
import ArrowSvg from "../../../assets/svg/arrow.svg";
import TimeLineItem from "./TimeLineItem/timeLineItem";
import Carousel from "@brainhubeu/react-carousel";
import cx from "classnames";
import SliderArrows from "../../SliderArrows/SliderArrows";

const timeLineItems = [
  {
    id: 0,
    timePeriod: "home.timeline.before",
    Icon: engageIcon,
  },
  {
    id: 1,
    Icon: phoneIcon,
  },
  {
    id: 2,
    Icon: historyIcon,
  },
  {
    id: 3,
    timePeriod: "home.timeline.during",
    Icon: communicationIcon,
  },
  {
    id: 4,
    Icon: injectionIcon,
  },
  {
    id: 5,
    timePeriod: "home.timeline.post",
    Icon: temperatureIcon,
  },
  {
    id: 6,
    Icon: paymentIcon,
  },
  {
    id: 7,
    Icon: peopleIcon,
  },
].map((item, index) => ({
  ...item,
  titleId: `home.timeline.${index + 1}.title`,
  descriptionId: `home.timeline.${index + 1}.description`,
}));

class TimeLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItemIndex: 0,
      visibleItems: 4,
    };
    this.sliderItem = React.createRef();
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    let visibleItems = 1;
    if (window.innerWidth >= 768) {
      visibleItems = 2;
    }
    if (window.innerWidth > 1280) {
      visibleItems = 4;
    }
    this.setState({
      visibleItems,
      activeItemIndex: 0,
    });
  };
  nextHandler = () => {
    const { activeItemIndex, visibleItems } = this.state;
    if (activeItemIndex < timeLineItems.length - visibleItems) {
      this.setState({ activeItemIndex: activeItemIndex + 1 });
    }
  };

  prevHandler = () => {
    const { activeItemIndex } = this.state;
    if (activeItemIndex > 0) {
      this.setState({ activeItemIndex: activeItemIndex - 1 });
    }
  };

  handleChange = (activeItemIndex) => {
    this.setState({ activeItemIndex });
  };

  render() {
    const { activeItemIndex, visibleItems } = this.state;
    return (
      <div className={styles.featureSlider}>
        <div className="timeline">
          <Carousel
            value={activeItemIndex}
            draggable
            onChange={this.handleChange}
            slidesPerPage={visibleItems + 0.4}
          >
            {timeLineItems.map((item, index) => (
              <div
                key={item.id}
                className={styles.sliderItem}
                ref={index === 0 && this.sliderItem}
              >
                <TimeLineItem
                  {...item}
                  hideArrow={index === timeLineItems.length - 1}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className={styles.sliderNavigation}>
          <button
            onClick={this.prevHandler}
            type="button"
            className={cx({
              [styles.sliderNavigation__arrow]: true,
              [styles.sliderNavigation__arrow_prev]: true,
              [styles.sliderNavigation__arrow_disabled]: activeItemIndex === 0,
            })}
            aria-label="Previous"
          >
            <ArrowSvg />
          </button>
          <button
            onClick={this.nextHandler}
            type="button"
            className={cx({
              [styles.sliderNavigation__arrow]: true,
              [styles.sliderNavigation__arrow_next]: true,
              [styles.sliderNavigation__arrow_disabled]:
                activeItemIndex === timeLineItems.length - visibleItems,
            })}
            aria-label="Next"
          >
            <ArrowSvg />
          </button>
        </div>
        <div className={styles.sliderNavigationMobile}>
          <SliderArrows goPrev={this.prevHandler} goNext={this.nextHandler} />
        </div>
      </div>
    );
  }
}

export default TimeLine;
