import React from "react";
import * as styles from "./learnMoreBox.module.scss";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

const LearnMoreBox = ({ icon, titleId, descriptionId, animation, index }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.learnMoreBox}>
      <div className={styles.learnMoreBox_icon}>
        <img src={icon} alt="" />
        <div className={styles[`smallDot${index}`] + " " + styles[animation]} />
        <div className={styles[`bigDot${index}`] + " " + styles[animation]} />
      </div>
      <h3 className={styles.learnMoreBox_title}>{t(titleId)}</h3>
      <p className={styles.learnMoreBox_desc}>{t(descriptionId)}</p>
      <Link
        to="/for-clinics"
        className={styles.learnMoreBox_action + " " + styles[animation]}
      >
        {t("home.learMore.button")}
      </Link>
    </div>
  );
};

export default LearnMoreBox;
