// extracted by mini-css-extract-plugin
export var wrapper = "patientJourney-module--wrapper--qItpq";
export var description = "patientJourney-module--description--1LhtG";
export var title = "patientJourney-module--title--3m6ET";
export var text = "patientJourney-module--text--jLkCm";
export var photo = "patientJourney-module--photo--hTGgk";
export var messages = "patientJourney-module--messages--3PERH";
export var messages__item = "patientJourney-module--messages__item--37cMr";
export var message = "patientJourney-module--message--2sr9l";
export var message_middle = "patientJourney-module--message_middle--2hr7w";
export var message__title = "patientJourney-module--message__title--3tfsy";
export var message__description = "patientJourney-module--message__description--53XmF";