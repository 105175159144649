import * as styles from "./trustedBy.module.scss";
import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import { useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";

const TrustedBy = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.trustedByWrapper}>
      <p className={styles.title}>{t("home.trustedBy")}</p>
      <Carousel
        arrows={false}
        slidesPerPage={5}
        autoPlay={2000}
        offset={20}
        infinite
        lazyLoad
        breakpoints={{
          1000: {
            slidesPerPage: 4,
          },
          768: {
            slidesPerPage: 3,
          },
        }}
      >
        <StaticImage
          src={"./images/Agora.png"}
          alt={"The Agora Clinic"}
          placeholder="tracedSVG"
        />
        <StaticImage
          src={"./images/FA.png"}
          alt={"Fertility Associates"}
          placeholder="tracedSVG"
        />
        <StaticImage
          src={"./images/Herts Essex Fertility.png"}
          alt={"Herts & Essex Fertility Centre"}
          placeholder="tracedSVG"
        />
        <StaticImage  
          src={"./images/HSFC.png"}
          alt={"Harley Street Fertility Clinic"}
          placeholder="tracedSVG"
        />
        <StaticImage
          src={"./images/IB.png"}
          alt={"Instituto Bernabeu"}
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="KinderwunschPraxis"
          src="./images/KinderwunschPraxis.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="London Egg Bank"
          src="./images/LEB.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="London Women's Clinic"
          src="./images/LWC.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="Manchester Fertility"
          src="./images/Manchester Fertility.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="Santa Monica Fertility"
          src="./images/Santa Monica.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="Sims IVF"
          src="./images/Sims.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="IVF Spring Fertility Center"
          src="./images/Spring.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="CCRM Fertility"
          src="./images/CCRM.png"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="IVF Life"
          src="./images/IVFLife.jpeg"
          placeholder="tracedSVG"
        />
        <StaticImage
          alt="Manchester University"
          src="./images/ManchesterUniversity.jpeg"
          placeholder="tracedSVG"
        />
      </Carousel>
    </div>
  );
};

export default TrustedBy;
