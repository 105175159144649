// extracted by mini-css-extract-plugin
export var slider = "verticalSlider-module--slider--ELGU7";
export var list = "verticalSlider-module--list--8hILV";
export var list__item = "verticalSlider-module--list__item--I8mSK";
export var item__name = "verticalSlider-module--item__name--u0KPj";
export var item = "verticalSlider-module--item--1VSS2";
export var item_active = "verticalSlider-module--item_active--13JXq";
export var item__description = "verticalSlider-module--item__description--3c2Sr";
export var indicator = "verticalSlider-module--indicator--2IQ_v";
export var sliderMobile = "verticalSlider-module--sliderMobile--11A9s";
export var sliderMobile__item = "verticalSlider-module--sliderMobile__item--14Lpt";
export var sliderMobile__text = "verticalSlider-module--sliderMobile__text--2wgSH";
export var sliderMobile__name = "verticalSlider-module--sliderMobile__name--QR0zt";
export var sliderMobile__description = "verticalSlider-module--sliderMobile__description--1LVD_";
export var sliderMobile__button = "verticalSlider-module--sliderMobile__button--UzyQS";
export var sliderMobile__button_prev = "verticalSlider-module--sliderMobile__button_prev--MvhxU";
export var sliderMobile__button_next = "verticalSlider-module--sliderMobile__button_next--3Agbi";
export var sliderMobile__button_inactive = "verticalSlider-module--sliderMobile__button_inactive--1HkqA";
export var image = "verticalSlider-module--image--2rzoo";
export var image_active = "verticalSlider-module--image_active--rpKXn";
export var sliderDesktop = "verticalSlider-module--sliderDesktop--33NTU";
export var phone = "verticalSlider-module--phone--17FVs";
export var imageWrapper = "verticalSlider-module--imageWrapper--2CZ5M";