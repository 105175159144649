import * as styles from "./learnMore.module.scss";
import React from "react";
import phoneIconBlack from "../../../assets/images/earphone.png";
import cameraIconBlack from "../../../assets/images/camera.png";
import paperPlaneIconBlack from "../../../assets/images/paper.png";
import engageIconBlack from "../../../assets/images/engage.png";
import LearnMoreBox from "./LearnMoreBox/learnMoreBox";

const boxes = [
  phoneIconBlack,
  cameraIconBlack,
  paperPlaneIconBlack,
  engageIconBlack,
].map((icon, index) => ({
  index: index + 1,
  icon,
  titleId: `home.learnMore.${index + 1}.title`,
  descriptionId: `home.learnMore.${index + 1}.description`,
  animation: `type${index + 1}`,
}));

const LearnMore = () => {
  return (
    <div className={styles.learnMoreWrapper}>
      {boxes.map((box) => (
        <LearnMoreBox key={box.index} {...box} />
      ))}
    </div>
  );
};

export default LearnMore;
