import React from "react";
import * as styles from "./patientJourney.module.scss";
import photo from "../../../assets/images/woman-phone.png";
import ScrollAnimation from "react-animate-on-scroll";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const messages = [
  {
    id: 0,
    title: "Salve",
    description: "We’ve sent your Welcome Pack. Tap to take a look.",
    delay: 900,
    offset: 0,
  },
  {
    id: 1,
    title: "Salve",
    description:
      " For your egg collection tomorrow, don’t forget to arrange for someone to take you home after the procedure.",
    middle: true,
    delay: 400,
    offset: 200,
  },
  {
    id: 2,
    title: "Salve",
    description:
      "Hi Jane. This is a quick note to send you positive thoughts during your two-week wait!",
    delay: 100,
    offset: 700,
  },
];

const PatientJourney = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        <h3 className={styles.title}>{t("home.reinvent.header")}</h3>
        <p className={styles.text}>{t("home.reinvent.subheader")}</p>
      </div>
      <div className={styles.photo}>
        <div className={styles.messages}>
          {messages.map((message) => (
            <ScrollAnimation
              key={message.id}
              animateIn="fadeInRight"
              delay={message.delay}
              offset={message.offset}
              animateOnce
            >
              <div
                key={message.id}
                className={cx({
                  [styles.messages__item]: true,
                  [styles.message]: true,
                  [styles.message_middle]: message.middle,
                })}
              >
                <div>
                  <div className={styles.message__title}>{message.title}</div>
                  <div className={styles.message__description}>
                    {message.description}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          ))}
        </div>
        <img src={photo} alt="" />
      </div>
    </div>
  );
};

export default PatientJourney;
