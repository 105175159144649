import * as styles from "./verticalSlider.module.scss";
import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import Carousel from "@brainhubeu/react-carousel";
import cx from "classnames";
import ArrowBlueSvg from "../../../../assets/svg/arrowBlue.svg";

class VerticalSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAnimating: false,
      clicked: false,
      sliderType: "desktop",
      indicatorY: 0,
      indicatorHeight: 0,
      activeIndex: 0
    };
    this.itemsRef = new Array(this.props.data.length);
    this.startAnimation = this.startAnimation.bind(this);
    this.pauseAnimation = this.pauseAnimation.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setItemRef = this.setItemRef.bind(this);
    this.setIteratorSizes = this.setIteratorSizes.bind(this);
    this.onChangeSliderAction = this.onChangeSliderAction.bind(this);
  }

  sliderInterval = null;

  componentDidMount() {
    this.handleResize();
    this.setIteratorSizes();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.pauseAnimation()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeIndex !== this.state.activeIndex) {
      this.setIteratorSizes();
    }
  }

  handleResize = () => {
    if (window.innerWidth <= 680) {
      this.setState({
        sliderType: "mobile",
      });
      this.pauseAnimation();
    } else {
      this.setState({ sliderType: "desktop" });
      if (this.state.isAnimating === false) {
        this.startAnimation();
      }
    }
  };

  onChangeSliderAction(activeIndex) {
    this.setState({ activeIndex });
  }

  startAnimation() {
    this.setState({ isAnimating: true, clicked: false });
    if (this.sliderInterval != null) {
      clearInterval(this.sliderInterval);
    }
    this.sliderInterval = setInterval(() => {
      const { data } = this.props;
      const { activeIndex } = this.state;
      this.onChangeSliderAction((activeIndex + 1) % data.length);
    }, 2000);
  }

  pauseAnimation() {
    this.setState({
      isAnimating: false,
    });
    clearInterval(this.sliderInterval);
  }

  handleClick(index) {
    this.setState({
      clicked: true,
    });
    this.onChangeSliderAction(index);
    this.pauseAnimation();
  }

  setItemRef(index, ref) {
    this.itemsRef[index] = ref;
  }

  setIteratorSizes() {
    const { sliderType } = this.state;
    if (sliderType === "mobile") {
      return;
    }
    const { activeIndex } = this.state;
    const [activeItemRef, ...restRefs] = this.itemsRef
      .slice(0, activeIndex + 1)
      .reverse();
    this.setState({
      indicatorHeight: activeItemRef.clientHeight,
      indicatorY: restRefs.reduce((total, ref) => total + ref.clientHeight, 0),
    });
  }

  render() {
    const { data} = this.props;
    const { clicked, indicatorY, indicatorHeight, activeIndex } = this.state;
    return (
      <>
        {this.state.sliderType === "desktop" && (
          <>
            <ScrollAnimation animateIn="fadeInDown" animateOnce>
              <div className={styles.sliderDesktop}>
                <div className={styles.phone}>
                  <div className={styles.imageWrapper}>
                    {data.map(({ image, index }) => (
                      <img
                        key={index}
                        src={image}
                        className={cx({
                          [styles.image]: true,
                          [styles.image_active]: index === activeIndex,
                        })}
                        alt=""
                      />
                    ))}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInRight" delay={500} animateOnce>
              <div className={styles.slider}>
                <div
                  className={styles.indicator}
                  style={{
                    transform: `translateY(${indicatorY}px) scaleY(${
                      indicatorHeight / 100
                    })`,
                  }}
                />
                <ul
                  className={styles.list}
                  onMouseEnter={() => !clicked && this.pauseAnimation()}
                  onMouseLeave={() => !clicked && this.startAnimation()}
                >
                  {data.map(({ index: id, titleId, descriptionId }, index) => (
                    <li
                      key={id}
                      ref={(ref) => this.setItemRef(index, ref)}
                      className={cx({
                        [styles.list__item]: true,
                        [styles.item]: true,
                        [styles.item_active]: activeIndex === index,
                      })}
                    >
                      <div
                        role="button"
                        onClick={() => this.handleClick(index)}
                        onKeyPress={() => this.handleClick(index)}
                        tabIndex={0}
                      >
                        <p className={styles.item__name}>
                          <Trans>{titleId}</Trans>
                        </p>
                        <p className={styles.item__description}>
                          <Trans>{descriptionId}</Trans>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </ScrollAnimation>
          </>
        )}
        {this.state.sliderType === "mobile" && (
          <div className={styles.sliderMobile}>
            <Carousel
              value={activeIndex}
              draggable
              onChange={this.handleClick}
              slidesPerPage={1}
            >
              {data.map(({ index: id, titleId, descriptionId, image }) => (
                <div className={styles.sliderMobile__item} key={id}>
                  <img
                    key={id}
                    src={image}
                    className={cx({
                      [styles.image]: true,
                      [styles.image_active]: id === activeIndex,
                    })}
                    alt=""
                  />
                  <div className={styles.sliderMobile__text}>
                    <p className={styles.sliderMobile__name}>
                      <Trans>{titleId}</Trans>
                    </p>
                    <p className={styles.sliderMobile__description}>
                      <Trans>{descriptionId}</Trans>
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>

            <button
              onClick={() =>
                activeIndex !== 0 && this.onChangeSliderAction(activeIndex - 1)
              }
              type="button"
              className={cx({
                [styles.sliderMobile__button]: true,
                [styles.sliderMobile__button_prev]: true,
                [styles.sliderMobile__button_inactive]: activeIndex === 0,
              })}
            >
              <ArrowBlueSvg />
            </button>
            <button
              onClick={() =>
                activeIndex !== data.length - 1 &&
                this.onChangeSliderAction(activeIndex + 1)
              }
              type="button"
              className={cx({
                [styles.sliderMobile__button]: true,
                [styles.sliderMobile__button_next]: true,
                [styles.sliderMobile__button_inactive]:
                  activeIndex === data.length - 1,
              })}
            >
              <ArrowBlueSvg />
            </button>
          </div>
        )}
      </>
    );
  }
}

export default VerticalSlider;
