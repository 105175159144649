// extracted by mini-css-extract-plugin
export var learnMoreBox = "learnMoreBox-module--learnMoreBox--1jK8W";
export var learnMoreBox_icon = "learnMoreBox-module--learnMoreBox_icon--2TXa4";
export var learnMoreBox_title = "learnMoreBox-module--learnMoreBox_title--1_cv6";
export var learnMoreBox_desc = "learnMoreBox-module--learnMoreBox_desc--1mXx8";
export var learnMoreBox_action = "learnMoreBox-module--learnMoreBox_action--aK40F";
export var type1 = "learnMoreBox-module--type1--1s0Ol";
export var type2 = "learnMoreBox-module--type2--DN5Vr";
export var type3 = "learnMoreBox-module--type3--3ZuuT";
export var type4 = "learnMoreBox-module--type4--3XjHz";
export var smallDot1 = "learnMoreBox-module--smallDot1--1kgBx";
export var moveSmall1 = "learnMoreBox-module--moveSmall1--2AxwT";
export var bigDot1 = "learnMoreBox-module--bigDot1--UVGNN";
export var moveBig1 = "learnMoreBox-module--moveBig1--1j6Kh";
export var smallDot2 = "learnMoreBox-module--smallDot2--3MtFM";
export var moveSmall2 = "learnMoreBox-module--moveSmall2--pwsce";
export var bigDot2 = "learnMoreBox-module--bigDot2--1exQ5";
export var moveBig2 = "learnMoreBox-module--moveBig2--18Lr8";
export var smallDot3 = "learnMoreBox-module--smallDot3--1O-nn";
export var moveSmall3 = "learnMoreBox-module--moveSmall3--fc9j3";
export var bigDot3 = "learnMoreBox-module--bigDot3--1AMHF";
export var moveBig3 = "learnMoreBox-module--moveBig3--1alc4";
export var smallDot4 = "learnMoreBox-module--smallDot4--1A25q";
export var moveSmall4 = "learnMoreBox-module--moveSmall4--OjzGP";
export var bigDot4 = "learnMoreBox-module--bigDot4--3cXvj";
export var moveBig4 = "learnMoreBox-module--moveBig4--tnxfw";
export var moveSmall = "learnMoreBox-module--moveSmall--2xlLG";
export var moveBig = "learnMoreBox-module--moveBig--2Xt8b";