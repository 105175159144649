import * as styles from "./features.module.scss";
import React from "react";
import phoneImage from "./../../PhoneSection/images/home.png";
import callImage from "./../../PhoneSection/images/call.png";
import chatImage from "./../../PhoneSection/images/chat.png";
import paymentImage from "./../../PhoneSection/images/pay.png";
import formsImage from "./../../PhoneSection/images/form.png";
import autoRemindImage from "./../../PhoneSection/images/notifications.png";
import crmImage from "./../../PhoneSection/images/notifications2.png";
import PlaySvg from "../../../assets/svg/play.svg";
import ButtonFull from "../../ButtonFull/buttonFull";
import ScrollAnimation from "react-animate-on-scroll";
import VerticalSlider from "./verticalSlider/verticalSlider";
import { Trans } from "gatsby-plugin-react-i18next";
import Modal from "../../Modal/Modal";
import ReactPlayer from "react-player/youtube";
import GetInTouchButton from "../../GetInTouchButton/GetInTouchButton";

const featuresData = [
  phoneImage,
  callImage,
  chatImage,
  paymentImage,
  formsImage,
  autoRemindImage,
  crmImage,
].map((image, index) => ({
  index,
  image,
  titleId: `home.feature.${index + 1}.title`,
  descriptionId: `home.feature.${index + 1}.description`,
}));

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      isModalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
    });
  }

  render() {
    const { isModalOpen } = this.state;
    return (
      <div className={styles.featuresWrapper}>
        <ScrollAnimation animateIn="fadeInLeft" delay={300} animateOnce>
          <div className={styles.platformDescription}>
            <h2 className={styles.platformDescription_title}>
              <Trans>home.header</Trans>
            </h2>
            <p className={styles.platformDescription_characterization}>
              <Trans>home.subheader</Trans>
            </p>
            <div className={styles.buttons}>
              <ButtonFull
                onClick={this.openModal}
                textId="button.seeInAction"
                Icon={PlaySvg}
              />
              <div className={styles.buttons__getInTouch}>
                <GetInTouchButton />
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <VerticalSlider data={featuresData} />
        <Modal onClose={this.closeModal} isOpen={isModalOpen}>
          <div className={styles.videoWrapper}>
            <ReactPlayer
              width="100%"
              height="100%"
              controls
              url="https://www.youtube.com/watch?v=JEb2dVpq65o&feature=youtu.be"
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Features;
