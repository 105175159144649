import * as styles from "./Opinion.module.scss";
import React, { FunctionComponent } from "react";
import { useTranslation } from 'react-i18next';

interface Props {
  id: number;
  photo: string;
  name: string;
}

const Opinion: FunctionComponent<Props> = ({ id, photo, name }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <img src={photo} className={styles.photo} alt={name} />
      <div className={styles.credentials}>
        <p>{name}</p>
        <p>{t(`opinions.${id}.position`)}</p>
      </div>
      <div className={styles.description}>{t(`opinions.${id}.description`)}</div>
    </div>
  );
};

export default Opinion;
