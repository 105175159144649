import * as styles from "./Review.module.scss";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import StarSvg from "../../../../assets/svg/star.svg";

interface Props {
  id: number;
  store: string;
  rate: number;
}

const ReviewOpinion: FunctionComponent<Props> = ({ id, store, rate }) => { 
  const { t } = useTranslation();
  return (
  <div className={styles.content}>
    <div>
      <div className={styles.stars}>
        {Array.from({ length: rate }, (_, i) => i).map((i) => (
          <StarSvg key={i} />
        ))}
      </div>
      <p className={styles.appStore}>{store}</p>
    </div>
    <div className={styles.description}>{t(`reviews.${id}.description`)}</div>
  </div>
)};

export default ReviewOpinion;
